interface InputProps {
  label: string;
  error?: string;
  type?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  onChange?: (value: string | number | undefined) => void;
}

export default function PriceInput({
  label,
  error,
  name,
  type = "text",
  placeholder,
  defaultValue,
  required,
  onChange,
  ...rest
}: InputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        {/* Input with currency mask */}
        <input
          type="number"
          name={name}
          id={name}
          className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-input-500 focus:ring-input-500 sm:text-sm"
          placeholder="0.00"
          defaultValue={defaultValue}
          onChange={handleChange}
          pattern="[0-9]*"
          min={0}
          step="0.01"
          aria-describedby="price-currency"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
    </div>
  );
}
