import { toast } from "react-hot-toast";
import {
  Form,
  LoaderFunctionArgs,
  ActionFunctionArgs,
  useLoaderData,
  redirect,
  useFetcher,
} from "react-router-dom";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { ShowLayout } from "../../layout/ShowLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "../../queries";
import { organizationStore } from "../../store/organization";
import { Attendee } from "../../types/attendee";
import { Event } from "../../types/event";
import { UPDATE_ATTENDEE } from "./mutations";
import {
  GET_ATTENDEE,
  GET_ATTENDEE_CARD,
  GET_ATTENDEE_ORDERS, GET_ATTENDEE_ORDERS_BY_ATTENDEE,
  GET_ATTENDEE_RFID,
} from "./queries";
import DeleteModal from "../../components/DeleteModal";
import { SecondaryButton } from "../../components/Button";
import { usePermissions } from "../auth/api";
import React, { useEffect, useRef, useState } from "react";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { rfidColDefs } from "./components/rfidAssetsHelpers";
import { cardsOnFileColDefs } from "./components/cardsOnFileHelpers";
import {
  CardsOnFileRow,
  RFIDAssetsRow,
  TransactionReportRow,
} from "./components/type";
import Loader from "../../components/Loader";
import { ordersColDefs } from "./components/ordersHelpers";
import { userStore } from "../../store/user";

const client = createApolloClient();

const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const attendeeEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getState().organizationId;
  const user = getUserStore().user;

  let where: any = {
    organization_id: { _eq: organizationId },
  };
  if (user?.events) {
    where = {
      ...where,
      id: {
        _in: user?.events,
      },
    };
  }
  const [{ data: attendeeData }, { data: eventsData }] = await Promise.all([
    client.query({
      query: GET_ATTENDEE,
      variables: {
        id: params?.id,
      },
    }),
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where,
      },
    }),
  ]);
  return {
    attendee: attendeeData.attendees_by_pk,
    events: eventsData.events,
  };
};

export const attendeeEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const body = await request.formData();
  const bodyData = Object.fromEntries(body);
  try {
    await client.mutate({
      mutation: UPDATE_ATTENDEE,
      variables: {
        input: bodyData,
        where: {
          id: { _eq: id },
        },
      },
    });
    toast.success("Attendee updated successfully");
    return redirect(`/attendees/${params.id}`);
  } catch (error) {
    toast.error("Error updating attendee");
  }
};

export const AttendeeEdit = () => {
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  const [isLoadingRFID, setIsLoadingRFID] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState(true);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const { attendee, events } = useLoaderData() as {
    attendee: Attendee;
    events: Event[];
  };
  const [orderRowData, setOrderRowData] = useState<TransactionReportRow[]>([]);
  const [cardRowData, setCardRowData] = useState<CardsOnFileRow[]>([]);
  const [rfidRowData, setRfidRowData] = useState<RFIDAssetsRow[]>([]);
  const fetcher = useFetcher();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rfidData] = await Promise.all([
          client.query({
            query: GET_ATTENDEE_RFID,
            variables: {
              id: attendee?.id,
              event_id: attendee?.event_id,
            },
          }),
        ]);
        setRfidRowData(rfidData.data.rfid_assets);
      } catch (error) {
        toast.error("Error Fetching RFID Data");
      } finally {
        setIsLoadingRFID(false);
      }
    };
    fetchData();
  }, [attendee]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cardData] = await Promise.all([
          client.query({
            query: GET_ATTENDEE_CARD,
            variables: {
              id: attendee?.id,
              event_id: attendee?.event_id,
            },
          }),
        ]);
        setCardRowData(cardData.data.card_on_files);
      } catch (error) {
        toast.error("Error Fetching Card Data");
      } finally {
        setIsLoadingCard(false);
      }
    };
    fetchData();
  }, [attendee]);

  useEffect(() => {
    const fetchOrders = async (uids: string[]) => {
      try {
        const orderData = await client.query({
          query: GET_ATTENDEE_ORDERS,
          variables: {
            uids,
            event_id: attendee?.event_id,
          },
        });

        const orderData2 = await client.query({
          query: GET_ATTENDEE_ORDERS_BY_ATTENDEE,
          variables: {
            attendeeId: attendee?.id,
            event_id: attendee?.event_id,
          },
        });

        // Combine the two datasets
        const combinedOrderData = [
          ...orderData.data.reports_transactions,
          ...orderData2.data.reports_transactions
        ];

        // Remove duplicates based on order_id
        const uniqueOrders = Array.from(
            new Set(combinedOrderData.map(order => order.order_id))
        ).map(order_id => combinedOrderData.find(order => order.order_id === order_id));

        // Set the unique data into OrderRowData
        setOrderRowData(uniqueOrders);
      } catch (error) {
        toast.error("Error Fetching Order Data: " + error);
      } finally {
        setIsLoadingOrder(false);
      }
    };

    if (rfidRowData.length > 0) {
      const uids = rfidRowData.map((row) => row.uid);
      fetchOrders(uids);
    } else {
      setIsLoadingOrder(false);
    }
  }, [rfidRowData, attendee]);

  const { deletePermission } = usePermissions("attendees");

  return (
    <div>
      <Form action={`/attendees/${attendee.id}/edit`} method="put">
        {deletePermission && (
          <div className="flex">
            <div className="ml-auto py-2">
              <DeleteModal
                onConfirm={() => {
                  fetcher.submit(
                    {},
                    {
                      method: "delete",
                      action: `/attendees/${attendee.id}/delete`,
                    }
                  );
                }}
              >
                {({ setOpen }) => (
                  <SecondaryButton onClick={() => setOpen(true)}>
                    Delete
                  </SecondaryButton>
                )}
              </DeleteModal>
            </div>
          </div>
        )}
        <FormLayout>
          <div className="col-span-6 sm:col-span-4">
            <Input
              label="Email"
              name="email"
              defaultValue={attendee?.email}
              updateValue
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              name="is_suspended"
              label="Is Suspended"
              defaultChecked={attendee.is_suspended}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              name="is_active"
              label="Is Active"
              defaultChecked={attendee.is_active}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="First name"
              name="first_name"
              defaultValue={attendee.first_name}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Last name"
              name="last_name"
              defaultValue={attendee.last_name}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Phone Number"
              name="phone_number"
              defaultValue={attendee.phone_number}
            />
          </div>
          <div className="col-span-6">
            <CustomSelect
              title="Event"
              name="event_id"
              defaultValue={attendee.event_id}
              options={[
                ...events.map((event) => ({
                  label: event.name,
                  value: event.id,
                })),
              ]}
            />
          </div>
          <div className="col-span-6 sm:col-span-6">
            <TextArea name="note" label="Note" defaultValue={attendee.note} />
          </div>
        </FormLayout>
      </Form>
      <div className="mt-2">
        <ShowLayout header="Cashless Assets">
          <div style={{ height: "25vh" }}>
            {isLoadingRFID ? (
              <Loader />
            ) : (
              <div
                className={"ag-theme-quartz"}
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  rowData={rfidRowData}
                  columnDefs={rfidColDefs}
                  rowHeight={rowHeight}
                  pagination={true}
                  gridOptions={gridOptions}
                  paginationPageSize={100}
                  onGridReady={(params) => (gridRef.current = params.api)}
                />
              </div>
            )}
          </div>
        </ShowLayout>
      </div>
      <div className="mt-2">
        <ShowLayout header="Cards On File">
          <div style={{ height: "25vh" }}>
            {isLoadingCard ? (
              <Loader />
            ) : (
              <div
                className={"ag-theme-quartz"}
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  rowData={cardRowData}
                  columnDefs={cardsOnFileColDefs}
                  rowHeight={rowHeight}
                  pagination={true}
                  gridOptions={gridOptions}
                  paginationPageSize={100}
                  onGridReady={(params) => (gridRef.current = params.api)}
                />
              </div>
            )}
          </div>
        </ShowLayout>
      </div>
      <div className="mt-2">
        <ShowLayout header="Orders">
          <div style={{ height: "25vh" }}>
            {isLoadingOrder ? (
              <Loader />
            ) : (
              <div
                className={"ag-theme-quartz"}
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  rowData={orderRowData}
                  columnDefs={ordersColDefs}
                  rowHeight={rowHeight}
                  autoSizeStrategy={autoSizeStrategy}
                  pagination={true}
                  gridOptions={gridOptions}
                  paginationPageSize={100}
                  onGridReady={(params) => (gridRef.current = params.api)}
                />
              </div>
            )}
          </div>
        </ShowLayout>
      </div>
    </div>
  );
};
