import { useState } from "react";
import toast from "react-hot-toast";
import { Form, ActionFunctionArgs, redirect, useLoaderData } from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { useOrganizationStore } from "../../store/organization";
import { VendorType } from "../../types/vendor";
import { CREATE_VENDOR } from "./mutations";
import CustomSelect from "../../components/customSelect";
import PercentageInput from "../../components/inputs/PercentageInput";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import PriceInput from "../../components/inputs/PriceInput";
import { userStore } from "../../store/user";
import {
  VENDOR_CATAGORIES,
} from "./queries";
import { Categories } from "../../types/item";
import { organizationStore } from "../../store/organization";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;
export const vendorLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const { data: vendorTypeData } = await client.query({
    query: VENDOR_CATAGORIES,
    variables: {
      where: organizationId
        ? {
          organization_id: {
            _in: [0, organizationId],
          },
          is_active: {
            _eq: true,
          },
          reference_type: {
            _eq: "vendor_type",
          },
        }
        : {},
    },
  });
  return {
    vendorType: vendorTypeData.dashboard_reference,
  };
}; 

export const createVendorAction = async ({ request }: ActionFunctionArgs) => {
  const user = getUserStore().user;
  const body = await request.formData();
  const inputData = Object.fromEntries(body);
  const name = inputData.name as string;
  const organization_id = inputData.organization_id as string;
  const type = inputData.type as string;
  const revenue_share = inputData.revenue_share as string;
  const flat_fee = inputData.flat_fee as string;

  if (!name || !type || !organization_id) {
    toast.error("Please fill out all required fields.");
    return null;
  }

  try {
    const revenueSharePer = (parseFloat(revenue_share || "0") / 100).toFixed(2);
    await client.mutate({
      mutation: CREATE_VENDOR,
      variables: {
        input: {
          ...inputData,
          flat_fee: flat_fee ? +flat_fee * 100 : null,
          revenue_share: revenueSharePer,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Vendor created");
    return redirect("/vendors/list");
  } catch (error) {
    console.error("Failed to create vendor:", error);
    toast.error("Failed to create vendor: " + error);
    return null;
  }
};

export const VendorCreate = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const organizations = useOrganizationStore((state) => state.organizations);
  const [type, setType] = useState<string>("");
  const { vendorType } = useLoaderData() as {
    vendorType: Categories[];
  };

  return (
      <Form action="/vendors/create" method="post">
        <FormLayout>
          <div className="col-span-4">
            <Input label="Name" name="name" required />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
                name="is_active"
                label="Is Active"
                defaultChecked={true}
            />
          </div>
          {permissions === "admin" && (
              <div className="col-span-6">
                <CustomSelect
                    title="Organization"
                    name="organization_id"
                    required
                    defaultValue={organizationId}
                    options={organizations.map((org) => ({
                      label: org.name,
                      value: org.id,
                    }))}
                />
              </div>
          )}
          {permissions !== "admin" && (
              <input
                  type="hidden"
                  value={organizationId ?? ""}
                  name="organization_id"
              />
          )}
          <div className="col-span-6">
            <CustomSelect
                title="Vendor Type"
                name="type"
                required
                defaultValue={type}
                onChange={(v) => setType(v)}
                options={[
                  ...(vendorType?.map((v) => ({
                    label: v.reference_label,
                    value: v.reference_value,
                  })) || []),
                ]}
            />
          </div>
          {type === VendorType.OTHER && (
              <div className="col-span-6">
                <Input label="Other Type" name="other_type" required />
              </div>
          )}
          <div className="col-span-6">
            <Input label="Point-of-Contact Name" name="poc_name" />
          </div>
          <div className="col-span-6">
            <Input label="Point-of-Contact E-mail" name="poc_email" />
          </div>
          <div className="col-span-6">
            <Input label="Point-of-Contact Phone Number" name="poc_phone" />
          </div>
          <div className="col-span-4">
            <PercentageInput
                defaultValue={0}
                label="Revenue Share"
                name="revenue_share"
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
                name="sales_tax_responsible"
                label="Sales Tax Responsible"
                defaultChecked={false}
            />
          </div>
          <div className="col-span-6">
            <PriceInput required label="Flat Fee" name="flat_fee" />
          </div>
        </FormLayout>
      </Form>
  );
};