import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { GET_ITEM } from "./queries";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {Item, ItemModifiers, TaxType} from "../../types/item";
import { ShowLayout } from "../../layout/ShowLayout";
import { ShowRow } from "../../components/ShowRow";
import { snakeCaseToSentenceCase, capitalize, usdFormat } from "../../utils";
import { Badge } from "../../components/Badge";

const client = createApolloClient();

export const itemShowLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { data } = await client.query({
    query: GET_ITEM,
    variables: {
      id,
    },
  });

  return data.items_by_pk;
};

const userDisplayedPrice = ({ tax, price, tax_percentage }: Item) => {
  switch (tax) {
    case TaxType.INCLUSIVE:
      return usdFormat.format(price / 100 / (1 + tax_percentage / 100));
    case TaxType.NO_TAX:
    case TaxType.EXCLUSIVE:
    default:
      return usdFormat.format(price / 100);
  }
};

export const ItemShow = () => {
  const item = useLoaderData() as Item;
  const taxType = item?.menu_items[0] && item?.menu_items?.[0]?.menu?.tax_type;

    const groupModifiersBySubType = (modifiers: ItemModifiers[]) => {
        return modifiers.reduce<Record<string, ItemModifiers[]>>((acc, cur) => {
            (acc[cur.sub_type] = acc[cur.sub_type] || []).push(cur);
            return acc;
        }, {});
    };
    const groupedModifiers = groupModifiersBySubType(item.modifiers);
  return (
    <ShowLayout header={capitalize(item.short_name || item.name)}>
      <ShowRow label="POS Name" value={item.name} />
      <ShowRow label="Full Name" value={item.short_name} />
      <ShowRow label="Description" value={item.description} />
      <ShowRow label="Is Available?" value={item.is_active ? "Yes" : "No"} />
      <ShowRow label="UPC" value={item.upc} />
      <ShowRow label="Price" value={usdFormat.format(item.price / 100)} />
      <ShowRow
        label="Tags"
        value={item.tags.map((tag) => (
          <Badge>{tag}</Badge>
        ))}
      />
      <ShowRow label="Event" value={item.event?.name} />
      <ShowRow label="Tax Included?" value={item.tax ? "Yes" : "No"} />
      <ShowRow
        label="Tax Type"
        value={snakeCaseToSentenceCase(item.tax || "Inclusive")}
      />
      <ShowRow label="Tax Percentage (%)" value={item.tax_percentage} />
      <ShowRow
        label="Total Tax"
        value={usdFormat.format((item.price / 100) * item.tax_percentage)}
      />
      <ShowRow label="User Displayed Price" value={userDisplayedPrice(item)} />
        <ShowRow label="Token Price" value={item.token_price} />
        <ShowRow label="Token Name" value={item.redeemable_token_name} />
        <ShowRow label="Token ID" value={item.redeemable_token_id} />
        <ShowRow label="Modifer Type" value={capitalize(item.modifier_type)} />
        <ShowRow label="Modifier Sub Types">
            <div>
                {Object.keys(groupedModifiers).map((subType) => (
                    <div key={subType}>
                        <h3><strong>{capitalize(subType)}</strong></h3>
                        <ul>
                            {groupedModifiers[subType].map((modifier) => (
                                <li key={modifier.id}>
                                    {`${modifier.name} : ${usdFormat.format(modifier.additional_price / 100)}`}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </ShowRow>
    </ShowLayout>
  );
};
