import { gql } from "@apollo/client";

export const GET_ITEM_MODIFIERS = gql`
  query items($where: item_modifiers_bool_exp) {
    item_modifiers(where: $where, order_by: { name: asc }) {
      id
      name
      type
      sub_type
      additional_price
      organization_id
      updated_at
      created_at
      is_active
      user {
        username
      }
    }
  }
`;

export const GET_ITEM_MODIFIER = gql`
  query GetItem($id: Int!) {
    item_modifiers_by_pk(id: $id) {
      id
      name
      type
      sub_type
      additional_price
      organization_id
      is_active
    }
  }
`;

export const GET_ITEM_MODIFIER_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: item_modifiers_bool_exp!) {
    item_modifiers(order_by: { name: asc }, where: $where) {
      id
      is_active
      name
      organization_id
      sub_type
      type
      additional_price
    }
  }
`;

export const GET_ITEM_MODIFIERS_TYPE = gql`
  query vendor_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
      filter_value
    }
  }
`;