import { useFetcher, useLocation, useRevalidator } from "react-router-dom";
import CustomSelect from "../customSelect";

import { useOrganizationStore } from "../../store/organization";
import { renderOrganizationOptions } from "../../modules/reports/utils";

export const OrganizationSelect = () => {
  const location = useLocation();
  const revalidator = useRevalidator();
  const fetcher = useFetcher();
  const organizationId = useOrganizationStore((state) => state.organizationId);
  console.log({ organizationId });
  const setOrganizationId = useOrganizationStore(
    (state) => state.setOrganizationId
  );
  const initOrganization = useOrganizationStore(
    (state) => state.initOrganization
  );
  const organizations = useOrganizationStore((state) => state.organizations);

  const handleChange = async (value: number) => {
    setOrganizationId(value);
    await initOrganization();

    if (location.pathname.includes("list")) {
      revalidator.revalidate();
    }
    fetcher.load("/");
  };

  if (!organizations.length) {
    return null;
  }
  if (organizations?.length > 0) {
    return (
      <CustomSelect
        onChange={handleChange}
        placeholderOnly="Organization"
        defaultValue={organizationId ? +organizationId : null}
        options={renderOrganizationOptions(organizations)}
      />
    );
  } else {
    return <></>;
  }
};
