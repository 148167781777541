import { Event } from "./event";
import { MenuItem } from "./menu";
import { Vendor } from "./vendor";

export enum TaxType {
  TAXED = "taxed",
  INCLUSIVE = "inclusive",
  EXCLUSIVE = "exclusive",
  NO_TAX = "no_tax",
}
export interface Categories {
  reference_value: string;
  reference_label: string;
  filter_value: string;
}
export interface Item {
  id: number;
  price: number;
  tags: string[];
  description?: string;
  short_name?: string;
  upc: string;
  image?: string;
  is_active?: boolean;
  created_at: string;
  updated_at: string;
  name: string;
  event_id: number;
  event?: Event;
  menu_items: MenuItem[];
  token_price: number;
  redeemable_token_id?: string;
  redeemable_token_name?: string;
  taxable_item?: boolean;
  tax: TaxType;
  category: string;
  tax_percentage: number;
  is_variable_price: boolean;
  quantity?: number;
  vendor?: Vendor;
  vendor_id?: number;
  modifier_type?: string;
  gl_account_number: string;
  gl_account_name: string;
  modifiers: ItemModifiers[];
  user: {
    username: string;
  };
}

export interface ItemModifiers {
  id: number;
  name: string;
  additional_price: number;
  type: string;
  sub_type: string;
  is_active?: boolean;
  created_at: string;
  updated_at: string;
  organization_id: number;
  user: {
    username: string;
  };
}
