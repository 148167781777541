import { organizationStore } from "../../store/organization";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  Form,
  useFetcher,
} from "react-router-dom";
import toast from "react-hot-toast";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../components/inputs/Input";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import PercentageInput from "../../components/inputs/PercentageInput";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_MENUS,
  GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { Vendor } from "../../types/vendor";
import { Event } from "../../types/event";
import { PaymentProcessor } from "../../types/paymentProcessor";
import CustomSelect from "../../components/customSelect";
import { Menu } from "../../types/menu";
import { GET_LOCATION, GET_LOCATION_TYPE } from "./queries";
import {
  CREATE_LOCATION_MENUS,
  DELETE_LOCATION_MENUS,
  UPDATE_LOCATION,
} from "./mutations";
import { Location } from "../../types/location";
import { userStore } from "../../store/user";
import { PERMISSIONS, usePermissions } from "../auth/api";
import { useEffect, useState } from "react";
import { TagsInput } from "../../components/inputs/TagsInput";
import DeleteModal from "../../components/DeleteModal";
import { SecondaryButton } from "../../components/Button";
import { Categories } from "../../types/item";

const client = createApolloClient();
type MenuIds = string;
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;

export const locationEditAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const user = getState().user;
  const { id } = params;
  const body = await request.formData();
  const menuIds: MenuIds[] = body.getAll("menuIds") as MenuIds[];
  body.delete("menuIds");
  const tokensString = body.get("redeemable_tokens") as string;
  body.delete("redeemable_tokens");
  const redeemable_tokens = tokensString?.split(",").map((tag) => tag.trim());
  const bodyData = Object.fromEntries(body);
  try {
    if (menuIds.length !== 1 || menuIds[0] !== "") {
      await client.mutate({
        mutation: DELETE_LOCATION_MENUS,
        variables: {
          id,
        },
      });
    }
    await client.mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        id,
        input: {
          ...bodyData,
          redeemable_tokens: redeemable_tokens || null,
          payment_processor_id: bodyData?.payment_processor_id || null,
          is_active: (body.get("is_active") as string) === "true",
          last_updated_by: user?.id,
        },
      },
    });
    if (menuIds.length !== 1 || menuIds[0] !== "") {
      await client.mutate({
        mutation: CREATE_LOCATION_MENUS,
        variables: {
          objects: menuIds.map((menuId) => ({
            location_id: id,
            menu_id: menuId,
          })),
        },
      });
    }

    toast.success("Location updated successfully");
    return redirect(`/locations/list`);
  } catch (error) {
    toast.error("Error updating location: " + error);
  }
};

export const locationEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const permissions = getState().permissions;
  const user = getState().user;

  const organizationId = getOrganizationState().organizationId;
  let vendorWhere = {};
  let eventWhere = {};
  let menuWhere: any = {};
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }

  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
    menuWhere = {
      ...menuWhere,
      event_id: {
        _in: user?.events,
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: { _eq: organizationId },
      is_active: { _eq: true },
    };
    menuWhere = {
      ...menuWhere,
      event: {
        ...(menuWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };
    if (user?.vendors) {
      menuWhere = {
        ...menuWhere,
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }
  }

  const [
    { data: locationData },
    { data: vendors },
    { data: events },
    { data: menus },
    { data: locations },
  ] = await Promise.all([
    client.query({
      query: GET_LOCATION,
      variables: { id },
    }),
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: vendorWhere,
      },
    }),
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: eventWhere,
      },
    }),
    client.query({
      query: GET_LIST_MENUS,
      variables: {
        where: menuWhere,
      },
    }),
    client.query({
      query: GET_LOCATION_TYPE,
      variables: {
        where: organizationId
        ? {
          organization_id: {
            _in: [0, organizationId],
          },
          is_active: {
            _eq: true,
          },
          reference_type: {
            _eq: "locations_location_type",
          },
        }
        : {},
      },
    }),
  ]);
  let paymentProcessors = { payment_processor_config: [] };
  const { data } = await client.query({
    query: GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _eq: organizationId,
            },
          }
        : {},
    },
  });
  paymentProcessors = data;
  return {
    location: locationData?.locations_by_pk,
    vendors: vendors?.vendors,
    events: events?.events,
    paymentProcessors: paymentProcessors?.payment_processor_config,
    menus: menus?.menus,
    locations: locations?.dashboard_reference,
  };
};

export const LocationEdit = () => {
  const [selectedEvent, setSelectedEvent] = useState<number>();
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const { location, vendors, events, paymentProcessors, menus, locations } =
    useLoaderData() as {
      location: Location;
      vendors: Vendor[];
      events: Event[];
      paymentProcessors: PaymentProcessor[];
      menus: Menu[];
      locations: Categories[];
    };
  const [eventZones, setEventZones] = useState<string[]>([]);
  useEffect(() => {
    setSelectedEvent(location.event_id);
  }, []);
  useEffect(() => {
    if (selectedEvent) {
      const selectedEventObj = events.find(
        (event) => event.id === selectedEvent
      );
      const zones = selectedEventObj?.location_zones || [];
      setEventZones(zones);
    }
  }, [selectedEvent, events]);
  const zoneOptions = [
    { name: location.zone, id: location.zone },
    ...eventZones.map((zone) => ({ id: zone, name: zone })),
  ];
  const sortedPaymentProcessors = [...paymentProcessors].sort((a, b) =>
    a.payment_processor.localeCompare(b.payment_processor)
  );
  const [filteredMenus, setFilteredMenus] = useState<Menu[]>(menus);
  useEffect(() => {
    if (selectedEvent) {
      const updatedMenus = menus.filter(
        (menu) => menu.event_id.toString() === selectedEvent.toString()
      );
      setFilteredMenus(updatedMenus);
    } else {
      setFilteredMenus([]);
    }
  }, [selectedEvent, menus]);
  const { deletePermission } = usePermissions("locations");
  const fetcher = useFetcher();
  return (
    <Form
      method="put"
      action={`/locations/${location.id}/edit`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/locations/${location.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-5">
          <Input
            label="Name"
            name="name"
            defaultValue={location.name}
            required
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={location.is_active}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Location Type"
            name="location_type"
            required
            defaultValue={location.location_type}
            options={[
              ...(locations?.map((v) => ({
                label: v.reference_label,
                value: v.reference_value,
              })) || []),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            options={[
              ...events.map((event) => ({
                value: event.id,
                label: event.name,
              })),
            ]}
            onChange={(e) => setSelectedEvent(e)}
            required
            defaultValue={location.event_id}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Zone"
            name="zone"
            options={[
              ...eventZones.map((zone) => ({
                value: zone,
                label: zone,
              })),
            ]}
            defaultValue={location?.zone}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Vendor"
            name="vendor_id"
            options={[
              ...vendors.map((vend) => ({
                value: vend.id,
                label: vend.name,
              })),
            ]}
            required
            defaultValue={location.vendor_id}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Menus"
            name="menuIds"
            options={[
              ...filteredMenus.map((menu) => ({
                value: menu.id,
                label: menu.name,
              })),
            ]}
            multi
            defaultValue={
              location?.location_menus?.map((location) => location?.menu_id) ||
              []
            }
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            name="payment_processor_id"
            title="Payment Processor"
            defaultValue={location.payment_processor_id}
            options={[
              { label: "PENDING", value: "" },
              ...sortedPaymentProcessors.map((p) => ({
                value: p.id,
                label: p.payment_processor,
              })),
            ]}
          />
        </div>
        <>
          <div className="col-span-6">
            <Input
              label="Processor Dynamic Descriptor"
              placeholder="4 Character DBA Abbreviation * Event Name Example: RPOS*RONIN LOCATION (Max 25 Characters)"
              name="dynamic_descriptor"
              defaultValue={location.dynamic_descriptor}
            />
          </div>
        </>
        <div className="col-span-6">
          <PercentageInput
            name="digital_surcharge_percentage"
            label="Service Fee (%)"
            defaultValue={location.digital_surcharge_percentage}
          />
        </div>
        {permissions === "admin" && (
          <>
            <div className="col-span-6">
              <TagsInput
                label="Redeemable Token Types"
                name="redeemable_tokens"
                placeholder="Add a Token Type (press 'enter' to add)"
                defaultValue={location.redeemable_tokens}
              />
            </div>
          </>
        )}
      </FormLayout>
    </Form>
  );
};
