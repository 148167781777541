import { gql } from "@apollo/client";

export const REPORTS_CLERK = gql`
  query reports_clerk($where: reports_financial_hourly_bool_exp!) {
    reports_financial_hourly(where: $where, order_by: { username: asc }) {
    cash_balance_transaction_count
    username
    role
    cash_transaction_count
    credit_transaction_count
    event_id
    event_name
    gross_cash_balance_sales
    gross_cash_balance_tips
    gross_cash_sales
    gross_credit_sales
    gross_credit_tips
    gross_promo_balance_sales
    gross_promo_balance_tips
    gross_rfid_sales
    gross_rfid_tips
    gross_token_sales
    location_id
    location_name
    menu_id
    menu_name
    net_promo_balance_sales
    net_refunds
    organization_id
    organization_name
    promo_balance_transaction_count
    rfid_transaction_count
    token_transaction_count
    total_digital_surcharge
    total_discounts
    total_gross_sales
    total_refunds
    total_tax
    total_tips
    total_transaction_count
    transaction_hour
    vendor_id
    vendor_name
    vendor_type
    }
  }
`;
