import toast from "react-hot-toast";
import { CREATE_PROMO_CODE } from "./mutations";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import {
  ActionFunctionArgs,
  Form,
  redirect,
  useLoaderData,
} from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import RadioGroup from "../../components/inputs/RadioGroup";
import PercentageInput from "../../components/inputs/PercentageInput";
import PriceInput from "../../components/inputs/PriceInput";
import { useEffect, useState } from "react";
import CustomSelect from "../../components/customSelect";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { useEventStore } from "../../store/event";
import {
  GET_PROMO_CODE_ITEMS,
  GET_PROMO_CODE_ITEM_CATEGORIES,
} from "./queries";
import { Categories, Item } from "../../types/item";
import { nanoid, customAlphabet } from "nanoid";
import {
  alphabet,
  appliesToOptions,
  promoCodeTypes,
  promoMethods,
  qrAlhabet,
  triggerRulesOptions,
} from "../../utils";
import { userStore } from "../../store/user";

const client = createApolloClient();
const { getState: getOrganizationStore } = organizationStore;
const { getState: getUserStore } = userStore;

export const promoCodeCreateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const user = getUserStore().user;

  const data = await request.formData();
  const inclusiveCategory = data.getAll("inclusive_category");
  const inclusiveItems = data.getAll("inclusive_items");
  const excludedCategory = data.getAll("excluded_category");
  let excludedItems = data.getAll("excluded_items");
  excludedItems = excludedItems?.filter((item) => item);

  const formData = Object.fromEntries(data);
  const quantity = parseInt(`${formData?.quantity || 1}`);
  const methodType = formData?.method_type;
  const promoCodeValueType = formData?.promo_code_value_type;
  const triggerRule = formData?.trigger_rule;
  const spendAmount = formData?.spend_amount;
  const spendQuantity = formData?.spend_quantity;
  const evaluateAgainst = formData?.evaluate_against;
  const discountAppliesTo = formData?.discount_applies_to;
  // console.log({formData, inclusiveCategory, quantity});
  for (let index = 0; index < quantity; index++) {
    const element = index;
    const nanoIdGenerate =
      methodType === "qr_code"
        ? customAlphabet(alphabet, 12)
        : customAlphabet(alphabet, 8);
    const uid = nanoIdGenerate();
    const percentage = (formData.percentage || "0") as string;
    const percentagePer = (parseFloat(percentage) / 100).toFixed(2);

    const promo_payload = {
      promo_code_value_type: promoCodeValueType,
      trigger: triggerRule
        ? {
            rule: triggerRule,
            spend_amount:
              triggerRule === "spend_threshold" ? +spendAmount * 100 : null,
            spend_quantity:
              triggerRule !== "spend_threshold" ? spendQuantity : null,
            evaluate_against: evaluateAgainst,
            discount_applies_to:
              promoCodeValueType !== "amount_off" ? discountAppliesTo : null,
          }
        : null,
      percentage: promoCodeValueType !== "amount_off" ? percentagePer : null,
      amount:
        promoCodeValueType === "amount_off" ? +formData?.amount * 100 : null,
      inclusive_items: inclusiveItems,
      inclusive_category: inclusiveCategory,
      excluded_category: excludedCategory,
      excluded_items: excludedItems,
    };
    const createData = {
      organization_id: formData.organization_id,
      event_id: formData.event_id,
      attendee_id: null,
      promo_payload,
      uid,
      promo_code_type: methodType,
      description: formData.description,
      last_updated_by: user?.id,
    };
    setTimeout(() => {}, 1000);
    try {
      await client.mutate({
        mutation: CREATE_PROMO_CODE,
        variables: { data: createData },
      });
      // if (quantity - 1 === index) {
      toast.success(`${index + 1}/${quantity} Promo code created.`);
      // }
    } catch (error) {
      console.log({ error });
      toast.error(`${index + 1} Failed to create promo code`);
    }
  }

  return redirect("/promocode/list");
};

export const promoCodeCreateLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const user = getUserStore().user;
  let promocodeItemWhere: any = {
    is_active: {
      _eq: true,
    },
  };
  if (organizationId) {
    promocodeItemWhere = {
      ...promocodeItemWhere,
      event: {
        organization_id: {
          _in: [organizationId],
        },
      },
    };
  }
  if (user?.events) {
    promocodeItemWhere = {
      event: {
        ...promocodeItemWhere?.event,
        id: {
          _in: user?.events,
        },
      },
    };
  }
  const { data: itemCategoriesData } = await client.query({
    query: GET_PROMO_CODE_ITEM_CATEGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          }
        : {
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          },
    },
  });

  const { data: itemData } = await client.query({
    query: GET_PROMO_CODE_ITEMS,
    variables: {
      where: promocodeItemWhere,
    },
  });
  return {
    itemCategories: itemCategoriesData.dashboard_reference,
    items: itemData.items,
  };
};

export const PromoCodeCreate = () => {
  const [type, setType] = useState(promoCodeTypes?.[0].value);
  const [triggerRule, setTriggerRule] = useState(
    triggerRulesOptions?.[0]?.value
  );

  const [appliesTo, setAppliesTo] = useState(appliesToOptions?.[0]?.value);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  const [inclusive, setInclusive] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });

  const [exclusive, setExclusive] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });
  const [inclusiveOptions, setInclusiveOptions] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });

  const [exclusiveOptions, setExclusiveOptions] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });

  const { itemCategories, items } = useLoaderData() as {
    itemCategories: Categories[];
    items: Item[];
  };

  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const eventId = useEventStore((state) => state.eventId);
  const { fetchAllEvents, allEvents: events } = useEventStore((state) => state);

  useEffect(() => {
    fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      // console.log(items)
      const filteredItems = items.filter(
        (item) => item.event_id === selectedEventId
      );
      // console.log("Filtered Items based on Event ID:", filteredItems);
      setInclusiveOptions((prevState) => ({
        ...prevState,
        item:
          prevState.category.length > 0
            ? filteredItems
                .filter((item) => inclusive.category.includes(item.category))
                .map((item) => item.id)
            : filteredItems.map((item) => item.id),
      }));
    }
  }, [selectedEventId, inclusive.category, items]);

  useEffect(() => {
    if (selectedEventId) {
      const filteredItems = items.filter(
        (item) => item.event_id === selectedEventId
      );
      // console.log("Filtered Items for Exclusive based on Event ID:", filteredItems);
      setExclusiveOptions((prevState) => ({
        ...prevState,
        item:
          prevState.category.length > 0
            ? filteredItems
                .filter(
                  (item) =>
                    !inclusive.item.includes(item.id) &&
                    inclusive.category.includes(item.category)
                )
                .map((item) => item.id)
            : filteredItems
                .filter((item) => !inclusive.item.includes(item.id))
                .map((item) => item.id),
      }));
    }
  }, [selectedEventId, inclusive.item, inclusive.category, items]);

  useEffect(() => {
    setExclusive((prevState) => ({
      ...prevState,
      category: itemCategories
        .filter(
          (category) => !inclusive.category.includes(category.reference_value)
        )
        .map((category) => category.reference_value),
    }));
  }, [inclusive.category, itemCategories]);

  return (
    <Form method="post" action="/promocode/create">
      <FormLayout>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={organizationId}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            required
            options={events.map((event) => ({
              label: event.name,
              value: event.id,
            }))}
            onChange={(value) => {
              const eventId = parseInt(value);
              setSelectedEventId(eventId);
              // console.log("Selected Event ID:", eventId);
            }}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Description"
            placeholder="Description"
            type="text"
            required
            name="description"
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Entry Method"
            name="method_type"
            required
            options={promoMethods.map((method) => ({
              label: method.label,
              value: method.value,
            }))}
          />
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-1">
              <RadioGroup
                label="Promo Code Type"
                defaultChecked={type}
                name="promo_code_value_type"
                onChange={(value) => setType(value)}
                choices={promoCodeTypes.map((promoCodeType) => ({
                  title: promoCodeType.label,
                  id: promoCodeType.value,
                }))}
              />
            </div>
            <div className="col-span-5">
              <div className="w-full" role="group" aria-labelledby="">
                {type === "percentage_off" ? (
                  <PercentageInput
                    defaultValue={0}
                    label="Percentage"
                    name="percentage"
                  />
                ) : (
                  <PriceInput required label="Amount" name="amount" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-8 gap-10">
            <div className="col-span-2">
              <RadioGroup
                name="trigger_rule"
                label="Trigger Rule"
                defaultChecked={triggerRule || ""}
                onChange={(value) => setTriggerRule(value)}
                choices={triggerRulesOptions.map((triggerRule) => ({
                  title: triggerRule.label,
                  id: triggerRule.value || "",
                }))}
              />
            </div>
            {triggerRule ? (
              <>
                <div className="col-span-2">
                  <RadioGroup
                    label="Evaluate Against"
                    name="evaluate_against"
                    defaultChecked={appliesTo}
                    onChange={(value) => setAppliesTo(value)}
                    choices={appliesToOptions.map((appliesTo) => ({
                      title: appliesTo.label,
                      id: appliesTo.value,
                    }))}
                  />
                </div>
                <div className="col-span-2">
                  {triggerRule === "spend_threshold" ? (
                    <PriceInput
                      required
                      label="Required Spend Amount"
                      name="spend_amount"
                    />
                  ) : (
                    <Input
                      required
                      label="Required Quantity"
                      placeholder="Quantity"
                      type="number"
                      name="spend_quantity"
                    />
                  )}
                </div>
                {type === "percentage_off" ? (
                  <div className="col-span-2">
                    <RadioGroup
                      label="Discount Applies To"
                      name="discount_applies_to"
                      defaultChecked={appliesTo}
                      onChange={(value) => setAppliesTo(value)}
                      choices={appliesToOptions.map((appliesTo) => ({
                        title: appliesTo.label,
                        id: appliesTo.value,
                      }))}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3">
              <CustomSelect
                isDisabled={inclusive.item.length > 0}
                title="Inclusive Category (OR)"
                name="inclusive_category"
                multi
                options={[
                  ...(itemCategories.map((category) => ({
                    label: category.reference_label,
                    value: category.reference_value,
                  })) || []),
                ]}
                onChange={(category) =>
                  setInclusive((prevState) => ({
                    ...prevState,
                    category: category as string[],
                  }))
                }
              />
            </div>
            <div className="col-span-3">
              <CustomSelect
                isDisabled={inclusive.category.length > 0}
                title="Inclusive Items (OR)"
                name="inclusive_items"
                multi
                options={items
                  .filter((item) => item.event_id === selectedEventId)
                  .map((item) => ({
                    label: item.short_name || "",
                    value: item.id,
                  }))}
                onChange={(item) =>
                  setInclusive((prevState) => ({
                    ...prevState,
                    item: item as number[],
                  }))
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-6 mt-2 gap-4">
            <div className="col-span-3">
              <CustomSelect
                title="Excluded Category"
                name="excluded_category"
                multi
                options={[
                  ...(itemCategories
                    .filter(
                      (category) =>
                        !inclusiveOptions.category.includes(
                          category.reference_value
                        )
                    )
                    .map((category) => ({
                      label: category.reference_label,
                      value: category.reference_value,
                    })) || []),
                ]}
                onChange={(category) =>
                  setExclusive((prevState) => ({
                    ...prevState,
                    category: category as string[],
                  }))
                }
              />
            </div>
            <div className="col-span-3">
              <CustomSelect
                title="Excluded Items"
                name="excluded_items"
                multi
                options={items
                  .filter(
                    (item) =>
                      item.event_id === selectedEventId &&
                      !inclusive.item.includes(item.id) &&
                      (inclusive.category.length === 0 ||
                        inclusive.category.includes(item.category))
                  )
                  .map((item) => ({
                    label: item.short_name || "",
                    value: item.id,
                  }))}
                onChange={(item) =>
                  setExclusive((prevState) => ({
                    ...prevState,
                    item: item as number[],
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-6 mt-4">
          <Input
            required
            label="Quantity"
            placeholder="Quantity"
            type="number"
            name="quantity"
          />
        </div>
      </FormLayout>
    </Form>
  );
};
