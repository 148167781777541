import { Outlet } from "react-router-dom";
import { ClerkList, clerksListLoader } from "./clerk-report";
import { FinancialReport, financialReportLoader } from "./financial-report";
import { EventReport, eventReportLoader } from "./event-report";
import { CashBalanceReport, cashBalanceReportLoader } from "./cash-balance-report";
import { locationSalesLoader, LocationSalesReport } from "./location-sales-report";
import { vendorSalesLoader, VendorSalesReport } from "./vendor-sales-report";
import { vendorTypeSalesLoader, VendorTypeSalesReport } from "./vendor-type-sales-report";
import { productMixLoader, ProductMixReport } from "./product-mix-report";
import { productMixSummaryLoader, ProductMixSummaryReport } from "./product-mix-summary-report";
import { TransactionsReport} from "./transactions-report";
import { requireAuth } from "../auth/RequireAuth";
import { Breadcrumb } from "../../components/Breadcrumb";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { PromoBalanceReport, promoBalanceReportLoader } from "./promo-balance-report";
import {
  FinancialCumulativeHourlyReport,
  financialCumulativeHourlyLoader,
} from "./financial-cumulative-hourly-report";
import {
  FinancialHourlyReport,
  financialHourlyLoader,
} from "./financial-hourly-report";
import {DeferredReport, deferredTransactionsLoader} from "./deferred-report";
import {AccessScansReport} from "./access-scans-report";
import {DiscountsReport, discountsReportLoader} from "./discounts-report";
import {WebCheckoutReport} from "./web-checkout-report";
import {glAccountLoader, GLAccountReport} from "./gl-account-report";

const ReportsIndex = () => {
  return (
    <div className="min-h-screen">
      <div className="my-4">
      </div>
      <Outlet />
    </div>
  );
};

export const reportsRouter = {
  path: "/reports",
  id: "reports",
  element: <ReportsIndex />,
  loader: requireAuth(),
  children: [
    {
      path: "clerk-report",
      id: "clerk-report",
      loader: clerksListLoader,
      errorElement: <ErrorBoundary />,
      element: <ClerkList />,
      handle: {
        crumb: () => (
          <Breadcrumb name="Clerk Report" href="/reports/clerk-report" />
        ),
      },
    },
    {
      path: "transaction-report",
      id: "transaction-report",
      element: <TransactionsReport />,
      // loader: transactionsLoader,
      handle: {
        crumb: () => (
          <Breadcrumb name="Transactions Report" href="/reports/transaction-report" />
        ),
      },
    },
    {
      path: "access-scans-report",
      id: "access-scans-report",
      element: <AccessScansReport />,
      // loader: transactionsLoader,
      handle: {
        crumb: () => (
            <Breadcrumb name="Access Scans Report" href="/reports/access-scans-report" />
        ),
      },
    },
    {
      path: "deferred-transaction-report",
      id: "deferred-transaction-report",
      element: <DeferredReport />,
      loader: deferredTransactionsLoader,
      handle: {
        crumb: () => (
            <Breadcrumb name="Deferred Transactions Report" href="/reports/deferred-transaction-report" />
        ),
      },
    },
    {
      path: "discounts-report",
      id: "discounts-report",
      element: <DiscountsReport />,
      loader: discountsReportLoader,
      handle: {
        crumb: () => (
            <Breadcrumb name="Discounts Report" href="/reports/discounts-report" />
        ),
      },
    },
    {
      path: "financial-report",
      id: "financial-report",
      element: <FinancialReport />,
      loader: financialReportLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Financial Report"
            href="/reports/financial-report"
          />
        ),
      },
    },
    {
      path: "cumulative-net-hourly-report",
      id: "cumulative-net-hourly-report",
      element: <FinancialCumulativeHourlyReport />,
      loader: financialCumulativeHourlyLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Net Cumulative Hourly Sales Report"
                href="/reports/cumulative-net-hourly-report"
            />
        ),
      },
    },
    {
      path: "net-hourly-report",
      id: "net-hourly-report",
      element: <FinancialHourlyReport />,
      loader: financialHourlyLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Net Hourly Sales Report"
                href="/reports/net-hourly-report"
            />
        ),
      },
    },
    {
      path: "event-report",
      id: "event-report",
      element: <EventReport />,
      loader: eventReportLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Event Sales Report"
                href="/reports/event-report"
            />
        ),
      },
    },
    {
      path: "location-report",
      id: "location-report",
      element: <LocationSalesReport />,
      loader: locationSalesLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Location Sales Report"
            href="/reports/location-report"
          />
        ),
      },
    },
    {
      path: "product-mix-report",
      id: "product-mix-report",
      element: <ProductMixReport />,
      loader: productMixLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Product Mix Report"
            href="/reports/product-mix-report"
          />
        ),
      },
    },
    {
      path: "product-mix-summary-report",
      id: "product-mix-summary-report",
      element: <ProductMixSummaryReport />,
      loader: productMixSummaryLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Product Mix Summary Report"
            href="/reports/product-mix-summary-report"
          />
        ),
      },
    },
    {
      path: "cash-balance-report",
      id: "cash-balance-report",
      element: <CashBalanceReport />,
      loader: cashBalanceReportLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Cash Balance Loaded Report"
                href="/reports/cash-balance-report"
            />
        ),
      },
    },
    {
      path: "promo-balance-report",
      id: "promo-balance-report",
      element: <PromoBalanceReport />,
      loader: promoBalanceReportLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Promo Balance Loaded Report"
                href="/reports/promo-balance-report"
            />
        ),
      },
    },
    {
      path: "vendor-report",
      id: "vendor-report",
      element: <VendorSalesReport />,
      loader: vendorSalesLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Vendor Sales Report"
            href="/reports/vendor-report"
          />
        ),
      },
    },
    {
      path: "vendor-type-report",
      id: "vendor-type-report",
      element: <VendorTypeSalesReport />,
      loader: vendorTypeSalesLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="Vendor Type Sales Report"
                href="/reports/vendor-type-report"
            />
        ),
      },
    },
    {
      path: "web-checkout-report",
      id: "web-checkout-report",
      element: <WebCheckoutReport />,
      // loader: transactionsLoader,
      handle: {
        crumb: () => (
            <Breadcrumb name="Web Checkout Report" href="/reports/web-checkout-report" />
        ),
      },
    },
    {
      path: "gl-account-report",
      id: "gl-account-report",
      element: <GLAccountReport />,
      loader: glAccountLoader,
      handle: {
        crumb: () => (
            <Breadcrumb
                name="GL Account Report"
                href="/reports/gl-account-report"
            />
        ),
      },
    },
  ],
};
