import { useLoaderData } from "react-router-dom";
import { ShowRow } from "../../../components/ShowRow";
import { ShowLayout } from "../../../layout/ShowLayout";
import { Order } from "../../../types/order";
import { usdFormat } from "../../../utils";
import { calculateDiscountAmount } from "../util";
import {round} from "lodash";

export const TotalList = () => {
  const order = useLoaderData() as Order;
    const totalDiscount = order.discount
        ? order.discount.reduce((acc, discount) => acc + discount.applied_discount, 0) / 100
        : 0;


    const finalDiscount = round(totalDiscount,2)
    console.log(order.discount)
  const refunded = order.transactions?.reduce((acc, transaction) => {
    const amountRefund =
      transaction?.payment?.status === "refund"
        ? transaction.payment.amount
        : 0;
    return acc + amountRefund;
  }, 0);

  const total =
    (order.subtotal + order.digital_surcharge + order.tip + order.tax) / 100 -
      finalDiscount -
    refunded / 100;

    const renderTokensRedeemed = () => {
        if (order.tokens_redeemed) {
            const tokensRedeemed = Object.keys(order.tokens_redeemed).map((token) => {
                const { redeemable_token_id, quantity } = order.tokens_redeemed[token];
                return `${redeemable_token_id} - ${quantity}`;
            });
            return tokensRedeemed.join(", ");
        }
        return "";
    };

  return (
    <ShowLayout header="Total">
      <ShowRow
        label="Subtotal"
        value={usdFormat.format(order.subtotal / 100)}
      />
      <ShowRow
        label="Service Fee"
        value={usdFormat.format(order.digital_surcharge / 100)}
      />
      <ShowRow label="Tip" value={usdFormat.format(order.tip / 100)} />
      <ShowRow label="Tax" value={usdFormat.format(order.tax / 100)} />
      <ShowRow label="Discounts" value={usdFormat.format(finalDiscount)} />
        <ShowRow label="Tokens Redeemed" value={renderTokensRedeemed()} />
      <ShowRow
        label="Amount Refunded"
        value={usdFormat.format(refunded / 100)}
      />
      <ShowRow label="Total" value={usdFormat.format(total)} />
    </ShowLayout>
  );
};
