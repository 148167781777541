import {
  ActionFunctionArgs,
  Form,
  redirect,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { DatePicker } from "../../components/DatePicker";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { CREATE_EVENT } from "./mutation";
import {
  GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { PaymentProcessor } from "../../types/paymentProcessor";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { TagsInput } from "../../components/inputs/TagsInput";
import { useState } from "react";
import PercentageInput from "../../components/inputs/PercentageInput";
import { userStore } from "../../store/user";
const { getState: getUserStore } = userStore;

const client = createApolloClient();
type PaymentType = "card" | "cash" | "rfid" | "qr";
const { getState } = organizationStore;

export const eventCreateLoader = async () => {
  const organizationId = getState().organizationId;
  const [{ data: paymentProcessors }, { data: vendorData }] = await Promise.all(
    [
      client.query({
        query: GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
        variables: {
          where: organizationId
            ? {
                organization_id: {
                  _eq: organizationId,
                },
              }
            : {},
        },
      }),
      client.query({
        query: GET_LIST_VENDORS_BY_ORGANIZATION,
        variables: {
          where: organizationId
            ? {
                organization_id: {
                  _eq: organizationId,
                },
              }
            : {},
        },
      }),
    ]
  );
  return {
    paymentProcessors: paymentProcessors.payment_processor_config,
    vendors: vendorData.vendors,
  };
};

export const eventCreateAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const body = await request.formData();
  let timezonediffs = 0;
  const paymentTypes: PaymentType[] = body.getAll(
    "payment_types"
  ) as PaymentType[];
  body.delete("payment_types");
  const tokensString = body.get("available_tokens") as string;
  const available_tokens =
    tokensString.length > 0
      ? tokensString.split(",").map((tag) => tag.trim())
      : [];
  const zonesString = body.get("location_zones") as string;
  const location_zones =
    zonesString.length > 0
      ? zonesString.split(",").map((tag) => tag.trim())
      : [];
  const tz = body.get("timezone");
  if (tz === "EDT") {
    timezonediffs = -4;
  } else if (tz === "EST") {
    timezonediffs = -5;
  } else if (tz === "CDT") {
    timezonediffs = -5;
  } else if (tz === "CST") {
    timezonediffs = -6;
  } else if (tz === "MDT") {
    timezonediffs = -6;
  } else if (tz === "MST") {
    timezonediffs = -7;
  } else if (tz === "PDT") {
    timezonediffs = -7;
  } else if (tz === "PST") {
    timezonediffs = -8;
  } else {
    timezonediffs = 0;
  }
  const slug = body.get("slug");

  if (slug === "") {
    body.delete("slug");
  } else if (typeof slug === "string") {
    const lowerCaseSlug = slug.trim().toLowerCase();
    body.set("slug", lowerCaseSlug);
  }
  const bodyData = Object.fromEntries(body);

  try {
    await client.mutate({
      mutation: CREATE_EVENT,
      variables: {
        id,
        input: {
          ...bodyData,
          available_tokens,
          location_zones,
          payment_types: paymentTypes,
          start_date: bodyData?.start_date || null,
          end_date: bodyData?.end_date || null,
          frontgate_site_id: bodyData?.frontgate_site_id || null,
          payment_processor_id: bodyData?.payment_processor_id || null,
          timezone_diff: timezonediffs,
          web_surcharge_percentage: bodyData?.web_surcharge_percentage || 0,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Event created");
    return redirect("/events/list");
  } catch (error) {
    toast.error("Failed to create event: " + error);
  }
};

export const EventCreate = () => {
  const organizationId = getState().organizationId;
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const [slugInputValue, setSlugInputValue] = useState("");
  const { paymentProcessors } = useLoaderData() as {
    paymentProcessors: PaymentProcessor[];
  };
  const sortedPaymentProcessors = [...paymentProcessors].sort((a, b) =>
    a.payment_processor.localeCompare(b.payment_processor)
  );
  const [logoutProtected, setLogoutProtected] = useState(false);
  return (
    <Form
      method="post"
      action="/events/create"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <FormLayout>
        <div className="col-span-4">
          <Input label="Event Name" name="name" required/>
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
              label="Is Active?"
              name="is_active"
              defaultChecked
          />
        </div>
        {permissions === "admin" && (
            <div className="col-span-6">
              <CustomSelect
                  title="Organization"
                  name="organization_id"
                  required
                  defaultValue={organizationId}
                  options={organizations.map((org) => ({
                    label: org.name,
                    value: org.id,
                  }))}
              />
            </div>
        )}
        {permissions !== "admin" && (
            <input
                type="hidden"
                value={organizationId ?? ""}
                name="organization_id"
            />
        )}
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Event Information
          </label>
          <div className="col-span-3">
            <DatePicker label="Start Date" name="start_date"/>
          </div>
          <div className="col-span-3">
            <DatePicker label="End Date" name="end_date"/>
          </div>
          <div className="col-span-3">
            <Input label="Start Time" name="start_time"/>
          </div>
          <div className="col-span-3">
            <Input label="End Time" name="end_time"/>
          </div>
          <div className="col-span-6">
            <CustomSelect
                title="Timezone"
                required
                name="timezone"
                options={[
                  {value: "EDT", label: "Eastern Daylight Time"},
                  {value: "EST", label: "Eastern Standard Time"},
                  {value: "CDT", label: "Central Daylight Time"},
                  {value: "CST", label: "Central Standard Time"},
                  {value: "MDT", label: "Mountain Daylight Time"},
                  {value: "MST", label: "Mountain Standard Time"},
                  {value: "PDT", label: "Pacific Daylight Time"},
                  {value: "PST", label: "Pacific Standard Time"},
                ]}
            />
          </div>
          <div className="col-span-3">
            <Input label="Street Address" name="address_street"/>
          </div>
          <div className="col-span-3">
            <Input label="Street Address 2" name="address_street_2"/>
          </div>
          <div className="col-span-3">
            <Input label="City" name="address_city"/>
          </div>
          <div className="col-span-1">
            <Input label="State" name="address_state"/>
          </div>
          <div className="col-span-2">
            <Input label="Zipcode" name="address_zipcode"/>
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Payment Configuration
          </label>
          <div className="col-span-6">
            <CustomSelect
                name="payment_processor_id"
                title="Payment Processor"
                required
                options={[
                  {label: "PENDING", value: ""},
                  ...sortedPaymentProcessors.map((p) => ({
                    value: p.id,
                    label: p.payment_processor,
                  })),
                ]}
            />
          </div>
          <>
            <div className="col-span-6">
                <Input
                    label="Processor Dynamic Descriptor"
                    placeholder="4 Character DBA Abbreviation * Event Name Example: RPOS*RONIN LOCATION (Max 25 Characters)"
                    name="dynamic_descriptor"
                />
              </div>
            </>
            <div className="col-span-6">
              <CustomSelect
                  title="Accepted Payments"
                  name="payment_types"
                  required
                  multi
                  options={[
                    {value: "card", label: "Card"},
                    {value: "cash", label: "Cash"},
                    {value: "rfid", label: "RFID"},
                    {value: "qr", label: "QR"},
                  ]}
              />
            </div>

            <div className="col-span-6">
              <CustomSelect
                  title="Currency"
                  name="currency"
                  options={[{value: "USD", label: "USD"}]}
              />
            </div>
            <div className="col-span-6">
              <TagsInput
                  label="Eligible Token Types"
                  name="available_tokens"
                  placeholder="Add a Token Type (press 'enter' to add)"
              />
            </div>
            <div className="col-span-6">
              <Input
                  label="Service Fee Label"
                  defaultValue="Service Fee"
                  name="digital_surcharge_label"
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <label className="col-span-6 block text-sm font-medium text-gray-700">
              Feature Configuration
            </label>
            <div className="col-span-6">
              <TagsInput
                  label="Event Zones"
                  name="location_zones"
                  placeholder="Add a Zone Name (press 'enter' to add)"
              />
            </div>
            <div className="col-span-2">
              <IndeterminateCheckbox
                  label="Password Protected Organizer Logout"
                  name="is_org_logout_protected"
                  defaultChecked={true}
              />
            </div>
            <div className="col-span-2">
              <IndeterminateCheckbox
                  label="Password Protected Clerk Logout"
                  name="is_clerk_logout_protected"
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <label className="col-span-6 block text-sm font-medium text-gray-700">
              Cashless Configuration
            </label>
            <div className="col-span-6">
              <Input label="Frontgate Site Id" name="frontgate_site_id"/>
            </div>
            <div className="col-span-6">
              <Input
                  label="Cashless/Ticketing URL Slug"
                  name="slug"
                  value={slugInputValue}
                  onChange={(value) => setSlugInputValue(value as string)}
              />
            </div>
            <div className="block text-sm font-medium text-gray-700 col-span-6">
              <a
                  href={`https://cashless.roninpos.app/events/${slugInputValue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-underline"
              >
                https://cashless.roninpos.app/events/{slugInputValue}
              </a>
              <br/>
              <a
                  href={`https://ticketing.roninpos.app/events/${slugInputValue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-underline"
              >
                https://ticketing.roninpos.app/events/{slugInputValue}
              </a>
            </div>
            <div className="col-span-6">
              <Input label="Cashless/Ticketing Logo URL" name="logo"/>
            </div>
            <div className="col-span-6">
              <Input
                  label="Cashless/Ticketing Background URL"
                  name="background_url"
              />
            </div>
            <div className="col-span-6">
              <PercentageInput
                  name="web_surcharge_percentage"
                  label="Ticketing Site Service Fee Value (%)"
                  defaultValue={0}
              />
            </div>
          </div>
          <div className="col-span-6">
            <TextArea label="Event Description" name="description"/>
          </div>
      </FormLayout>
    </Form>
);
};
