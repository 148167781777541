import { Fragment, ReactNode, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Input } from './inputs/Input';
import { PrimaryButton } from './Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
interface SendEmailModalProps {
  onConfirm: ({
    type,
    value,
    setOpen,
    setEmail,
    setPhoneNumber,
    setType,
  }: {
    type: string;
    value: string;
    setOpen: any;
    setEmail: any;
    setPhoneNumber: any;
    setType: any;
  }) => void;
  children: ({ setOpen, setType }: { setOpen: any; setType: any }) => ReactNode;
}

export default function PromoCodeSendModal({
  onConfirm,
  children,
}: SendEmailModalProps) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const cancelButtonRef = useRef(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleConfirm = async () => {
    if ((type === 'mail' && email) || (type === 'sms' && phoneNumber)) {
      onConfirm({
        type: type,
        value: type ? (type === 'mail' ? email : phoneNumber) : '',
        setOpen: setOpen,
        setEmail,
        setPhoneNumber,
        setType,
      });
    }
  };

  return (
    <>
      {children({ setOpen, setType })}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start flex justify-center ">
                    <div className=" mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Send Promo Code via {type === 'mail' ? 'Email' : 'SMS'}
                      </Dialog.Title>
                      <div className="flex flex-col items-center">
                        <div className="col-span-6 mt-2">
                          {type === 'mail' ? (
                            <Input
                              defaultValue={email}
                              placeholder="Email"
                              type="email"
                              name="email"
                              onChange={(value?: any) => setEmail(value || '')}
                            />
                          ) : (
                            <PhoneInput
                              defaultCountry={'us'}
                              value={phoneNumber}
                              onChange={(value?: any) =>
                                setPhoneNumber(value || '')
                              }
                            />
                          )}
                        </div>
                        <div className="col-span-6 mt-2">
                          <PrimaryButton onClick={() => handleConfirm()}>
                            Submit
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
