import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { organizationStore } from "../../store/organization";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_MENUS } from "./queries";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { LocationMenu, Menu } from "../../types/menu";
import { Badge } from "../../components/Badge";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { Input } from "../../components/inputs/Input";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { userStore } from "../../store/user";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventStore } = eventStore;
const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
  {
    event: {
      name: {
        _ilike: `%${search}%`,
      },
    },
  },
  {
    vendor: {
      name: {
        _ilike: `%${search}%`,
      },
    },
  },
  {
    location_menus: {
      location: {
        name: {
          _ilike: `%${search}%`,
        },
      },
    },
  },
];
type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Menu;
}) => JSX.Element;

const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Menu }) => string
): CellRenderer => {
  return (params) => (
    <>
      {permission ? (
        <Link to={linkFunc(params)}>
          <Badge>{params.value}</Badge>
        </Link>
      ) : (
        <Badge>{params.value}</Badge>
      )}
    </>
  );
};
const createMultipleLinksCellRenderer = (
  permission: boolean,
  linkFunc: (locationMenu: LocationMenu) => string
): CellRenderer => {
  return (params) => {
    const locations = params.data?.location_menus?.map(
      (locationMenu, index) => (
        <span key={index}>
          {permission ? (
            <Link to={linkFunc(locationMenu)}>
              <Badge>{locationMenu.location.name}</Badge>
            </Link>
          ) : (
            <Badge>{locationMenu.location.name}</Badge>
          )}
          {index < (params?.data?.location_menus?.length ?? 0) - 1 && " "}
        </span>
      )
    );
    return <>{locations}</>;
  };
};
export const menusListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getState().user;
  console.log({ user });
  let where: any = {
    _or: search ? buildSearch(search) : undefined,
  };
  if (organizationId && organizationId !==0 ) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }
  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  if (user?.vendors) {
    where = {
      ...where,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }
  const { data } = await client.query({
    query: GET_MENUS,
    variables: {
      where,
    },
  });
  return data.menus;
};

export const MenuList = () => {
  const { editCreatePermission } = usePermissions("menus");
  const { editCreatePermission: eventsEditPermission } =
    usePermissions("events");
  const { editCreatePermission: vendorsEditPermission } =
    usePermissions("vendors");
  const { editCreatePermission: locationsEditPermission } =
    usePermissions("locations");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const menus = useLoaderData() as Menu[];
  const [rowData, setRowData] = useState<Menu[]>([]);
  useEffect(() => {
    setRowData(menus);
  }, [menus]);
  const generateColDefs = (): ColDef<Menu>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/menus/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Menu Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/menus/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.event.name,
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data.event_id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor Name",
      valueGetter: (p) => p.data?.vendor?.name,
      cellRenderer: createCellRenderer(
        vendorsEditPermission,
        (p) => `/vendors/${p.data.vendor_id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Location(s) Name",
      valueGetter: (p) => p.data?.location_menus,
      valueFormatter: (params) => {
        const locations: LocationMenu[] = params.value || [];
        return locations.map((location) => location.location.name).join(", ");
      },
      cellRenderer: createMultipleLinksCellRenderer(
        locationsEditPermission,
        (locationMenu) => `/locations/${locationMenu.location.id}/edit`
      ),
      filter: false,
    },
    {
      headerName: "Tax Type",
      valueGetter: (p) => p.data?.tax_type,
      valueFormatter: (p) => {
        const taxType = p.data?.tax_type || "";
        return taxType.charAt(0).toUpperCase() + taxType.slice(1);
      },
    },
    {
      headerName: "Cash?",
      valueGetter: (p) => p.data?.is_cash,
      valueFormatter: (p) => {
        return p.data?.is_cash ? "Yes" : "No";
      },
    },
    {
      headerName: "Credit?",
      valueGetter: (p) => p.data?.is_credit,
      valueFormatter: (p) => {
        return p.data?.is_credit ? "Yes" : "No";
      },
    },
    {
      headerName: "RFID?",
      valueGetter: (p) => p.data?.is_rfid,
      valueFormatter: (p) => {
        return p.data?.is_rfid ? "Yes" : "No";
      },
    },
    {
      headerName: "QR?",
      valueGetter: (p) => p.data?.is_qr,
      valueFormatter: (p) => {
        return p.data?.is_qr ? "Yes" : "No";
      },
    },
    {
      headerName: "Tabs?",
      valueGetter: (p) => p.data?.is_tab,
      valueFormatter: (p) => {
        return p.data?.is_tab ? "Yes" : "No";
      },
    },
    {
      headerName: "Tappit?",
      valueGetter: (p) => p.data?.is_tappit,
      valueFormatter: (p) => {
        return p.data?.is_tappit ? "Yes" : "No";
      },
    },
    {
      headerName: "Tips?",
      valueGetter: (p) => p.data?.is_tips,
      valueFormatter: (p) => {
        return p.data?.is_tips ? "Yes" : "No";
      },
    },
    {
      headerName: "Custom Items?",
      valueGetter: (p) => p.data?.is_custom_item,
      valueFormatter: (p) => {
        return p.data?.is_custom_item ? "Yes" : "No";
      },
    },
    {
      headerName: "Discounts?",
      valueGetter: (p) => p.data?.is_discount,
      valueFormatter: (p) => {
        return p.data?.is_discount ? "Yes" : "No";
      },
    },
    {
      headerName: "Discounts Protected?",
      valueGetter: (p) => p.data?.is_discount_protected,
      valueFormatter: (p) => {
        return p.data?.is_discount_protected ? "Yes" : "No";
      },
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      field: "updated_at", // Assuming 'updated_at' is the field name
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<Menu>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Menus_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params); // Updated line
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };

  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Menus`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/menus/list?search=${s}`
                    : `/menus/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/menus/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
