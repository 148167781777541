import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import PriceInput from "../../components/inputs/PriceInput";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Categories, ItemModifiers } from "../../types/item";
import { GET_ITEM_MODIFIER, GET_ITEM_MODIFIERS_TYPE } from "./queries";
import { UPDATE_ITEM_MODIFIER } from "./mutations";
import { usePermissions } from "../auth/api";
import { userStore } from "../../store/user";
import CustomSelect from "../../components/customSelect";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { Vendor } from "../../types/vendor";
import { Event } from "../../types/event";
import { useState } from "react";
import DeleteModal from "../../components/DeleteModal";
import { SecondaryButton } from "../../components/Button";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const itemEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getOrganizationStore().organizationId;

  const { id } = params;
  const [{ data: itemData }, { data: vendorsData }, { data: eventData }, { data: itemsModifierTypeData }, { data: itemsModifierSubTypeData },] =
    await Promise.all([
      client.query({
        query: GET_ITEM_MODIFIER,
        variables: { id },
      }),
      client.query({
        query: GET_LIST_VENDORS_BY_ORGANIZATION,
        variables: {
          where: organizationId
            ? {
                organization_id: {
                  _eq: organizationId,
                },
              }
            : {},
        },
      }),
      client.query({
        query: GET_LIST_EVENTS_BY_ORGANIZATION,
        variables: {
          where: organizationId
            ? {
                organization_id: {
                  _eq: organizationId,
                },
              }
            : {},
        },
      }),
      client.query({
        query: GET_ITEM_MODIFIERS_TYPE,
        variables: {
          where: organizationId
            ? {
              organization_id: {
                _in: [0, organizationId],
              },
              is_active: {
                _eq: true,
              },
              reference_type: {
                _eq: "item_modifiers_type",
              },
            }
            : {},
        },
      }),
      client.query({
        query: GET_ITEM_MODIFIERS_TYPE,
        variables: {
          where: organizationId
            ? {
              organization_id: {
                _in: [0, organizationId],
              },
              is_active: {
                _eq: true,
              },
              reference_type: {
                _eq: "item_modifiers_sub_type",
              },
            }
            : {},
          },
        }),
  ]);

  return {
    item: itemData.item_modifiers_by_pk,
    vendors: vendorsData.vendors,
    events: eventData.events,
    itemsModifierTypeData: itemsModifierTypeData.dashboard_reference,
    itemsModifierSubTypeData: itemsModifierSubTypeData.dashboard_reference,
  };
};

export const itemEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const user = getState().user;

  const { id } = params;
  const body = await request.formData();
  const priceString = body.get("additional_price") as string;
  const price = parseFloat(priceString);
  let bodyData: any = Object.fromEntries(body);
  let input;
  input = {
    ...bodyData,
    additional_price: price * 100,
    last_updated_by: user?.id,
  };

  try {
    await client.mutate({
      mutation: UPDATE_ITEM_MODIFIER,
      variables: {
        input,
        where: {
          id: {
            _eq: id,
          },
        },
      },
    });

    toast.success("Item Modifier Updated");
    return redirect(`/item-modifiers/list`);
  } catch (error) {
    toast.error("Failed to Update Item Modifier" + error);
  }
};

export const ItemModifierEdit = () => {
  const organizations = useOrganizationStore((state) => state.organizations);

  const { item, vendors, events, itemsModifierTypeData, itemsModifierSubTypeData } = useLoaderData() as {
    item: ItemModifiers;
    vendors: Vendor[];
    events: Event[];
    itemsModifierTypeData: Categories[];
    itemsModifierSubTypeData: Categories[];
  };
  const [selectedType, setSelectedType] = useState(item.type);
   const [selectedSubType, setSelectedSubType] = useState(null);
  const { deletePermission } = usePermissions("items_modifiers");
  const fetcher = useFetcher();
  const filterSubTypes = (type: string) => {
    return itemsModifierSubTypeData.filter(subType => subType.filter_value === type);
  };

  const subTypes = selectedType ? filterSubTypes(selectedType) : [];
  return (
    <Form
      method="put"
      action={`/item-modifiers/${item.id}/edit`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/item-modifiers/${item.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
        <FormLayout>
            <div className="col-span-5">
                <CustomSelect
                    title="Type"
                    name="type"
                    defaultValue={item.type}
                    onChange={(v) => {
                        setSelectedType(v);
                        setSelectedSubType(null);
                    }}
                    options={[
                        ...(itemsModifierTypeData?.map((v) => ({
                            label: v.reference_label,
                            value: v.reference_value,
                        })) || []),
                    ]}
                />
            </div>
            <div className="col-span-1">
                <IndeterminateCheckbox
                    label="Is Active?"
                    name="is_active"
                    defaultChecked={item.is_active}
                />
            </div>
            {selectedType && (
                <div className="col-span-6">
                    <CustomSelect
                        key={selectedType}
                        title="Sub-Type"
                        name="sub_type"
                        defaultValue={item.sub_type}
                        onChange={(v) => setSelectedSubType(v)}
                        options={[
                            ...(subTypes?.map((v) => ({
                                label: v.reference_label,
                                value: v.reference_value,
                            })) || []),
                        ]}
                    />
                </div>
            )}
            <div className="col-span-6">
                <Input label="Modifier Name" name="name" defaultValue={item.name}/>
            </div>
            <div className="col-span-6">
                <PriceInput
                    label="Additional Price"
                    name="additional_price"
                    defaultValue={item.additional_price / 100}
                />
            </div>
            <input
                type="hidden"
                value={item.organization_id || 0}
                name="organization_id"
            />
        </FormLayout>
    </Form>
  );
};
