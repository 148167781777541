import { usdFormat } from "../../../utils";
import { ClerkReport } from "./type";
import { ColDef } from "ag-grid-community";

const generateColDefs = (): ColDef<ClerkReport>[] => [
  {
    headerName: 'Username',
    valueGetter: p => p.data?.username,
    pinned: 'left',
    sort: 'asc',
    cellStyle: params => {
      if (params.value === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Gross Sales',
    valueGetter: p => p.data?.total_gross_sales,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Cash Sales',
    valueGetter: p => p.data?.gross_cash_sales,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Credit Sales',
    valueGetter: params => {
      return params.data?.gross_credit_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross RFID Sales',
    valueGetter: params => {
      return params.data?.gross_rfid_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Cash Balance Sales',
    valueGetter: params => {
      return params.data?.gross_cash_balance_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Promo Balance Sales',
    valueGetter: params => {
      return params.data?.gross_promo_balance_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Token Sales',
    valueGetter: params => {
      return params.data?.gross_token_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Net Sales',
    valueGetter: params => {
      return params.data?.total_net_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Deduction - Discounts',
    valueGetter: params => {
      return params.data?.total_discounts;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Deduction - Refunds',
    valueGetter: params => {
      return params.data?.net_refunds;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Deduction - Taxes',
    valueGetter: params => {
      return params.data?.total_tax;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Deduction - Token Sales',
    valueGetter: params => {
      return params.data?.gross_token_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Deduction - Promo Balance Sales',
    valueGetter: params => {
      return params.data?.net_promo_balance_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Tips',
    valueGetter: params => {
      return params.data?.total_tips;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Credit Tips',
    valueGetter: params => {
      return params.data?.gross_credit_tips;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'RFID Tips',
    valueGetter: params => {
      return params.data?.gross_rfid_tips;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Cash Balance Tips',
    valueGetter: params => {
      return params.data?.gross_cash_balance_tips;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Promo Balance Tips',
    valueGetter: params => {
      return params.data?.gross_promo_balance_tips;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Service Fee',
    valueGetter: params => {
      return params.data?.total_digital_surcharge;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Refunds Value',
    valueGetter: params => {
      return params.data?.total_refunds;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Merchant Processed',
    valueGetter: params => {
      return params.data?.merchant_processed;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - Cash',
    valueGetter: params => {
      return params.data?.cash_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - Credit',
    valueGetter: params => {
      return params.data?.credit_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - RFID',
    valueGetter: params => {
      return params.data?.rfid_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - Cash Balance',
    valueGetter: params => {
      return params.data?.cash_balance_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - Promo Balance',
    valueGetter: params => {
      return params.data?.promo_balance_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: '# of Transactions - Tokens',
    valueGetter: params => {
      return params.data?.token_transaction_count;
    },
    valueFormatter: params => (params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
];

export const colDefs: ColDef<ClerkReport>[] = generateColDefs();


export const formatData = (data: ClerkReport[]) => {
  if (!data) return [];

  let totalRow: ClerkReport = {
    id: "total-row",
    username: "Total",
    total_gross_sales: 0,
    gross_cash_sales: 0,
    gross_credit_sales: 0,
    gross_rfid_sales: 0,
    gross_cash_balance_sales: 0,
    gross_promo_balance_sales: 0,
    total_net_sales: 0,
    total_discounts: 0,
    total_refunds: 0,
    net_refunds: 0,
    total_tax: 0,
    total_tips: 0,
    merchant_processed: 0,
    gross_cash_balance_tips: 0,
    gross_promo_balance_tips: 0,
    gross_credit_tips: 0,
    gross_rfid_tips: 0,
    total_digital_surcharge: 0,
    gross_token_sales: 0,
    cash_transaction_count: 0,
    credit_transaction_count: 0,
    rfid_transaction_count: 0,
    cash_balance_transaction_count: 0,
    promo_balance_transaction_count: 0,
    token_transaction_count: 0,
    net_token_sales: 0,
    net_promo_balance_sales: 0,
  };
  const groupedRows: Record<string, ClerkReport> = {};

  data.forEach((curr) => {
      const username = curr.username;
      const total_gross_sales = (curr.gross_cash_balance_sales+curr.gross_cash_sales+curr.gross_credit_sales+curr.gross_promo_balance_sales+curr.gross_rfid_sales+curr.gross_token_sales) / 100;
      const gross_cash_balance_sales = curr.gross_cash_balance_sales / 100;
      const gross_cash_sales = curr.gross_cash_sales / 100;
      const gross_credit_sales = curr.gross_credit_sales / 100;
      const gross_promo_balance_sales = curr.gross_promo_balance_sales / 100;
      const gross_rfid_sales = curr.gross_rfid_sales / 100;
      const net_refunds = curr.net_refunds / 100;
      const total_refunds = curr.total_refunds / 100;
      const total_tax = curr.total_tax / 100;
      const total_tips = curr.total_tips / 100;
      const total_digital_surcharge = curr.total_digital_surcharge / 100;
      const total_discounts = curr.total_discounts / 100;
      const total_net_sales = ((curr.gross_cash_balance_sales+curr.gross_cash_sales+curr.gross_credit_sales+curr.gross_promo_balance_sales+curr.gross_rfid_sales+curr.gross_token_sales) / 100)-
          ((curr.gross_token_sales+curr.net_refunds+curr.net_promo_balance_sales+curr.total_discounts+curr.total_tax)/100);
      const gross_cash_balance_tips = curr.gross_cash_balance_tips / 100;
      const gross_promo_balance_tips = curr.gross_promo_balance_tips / 100;
      const gross_credit_tips = curr.gross_credit_tips / 100;
      const gross_rfid_tips = curr.gross_rfid_tips / 100;
      const gross_token_sales = curr.gross_token_sales / 100;
      const net_token_sales = curr.net_token_sales / 100;
      const net_promo_balance_sales = curr.net_promo_balance_sales / 100;
      const cash_transaction_count = curr.cash_transaction_count;
      const credit_transaction_count = curr.credit_transaction_count;
      const rfid_transaction_count = curr.rfid_transaction_count;
      const cash_balance_transaction_count =
          curr.cash_balance_transaction_count;
      const promo_balance_transaction_count =
          curr.promo_balance_transaction_count;
      const token_transaction_count = curr.token_transaction_count;
      const merchant_processed = (curr.gross_rfid_tips + curr.gross_credit_tips + curr.gross_rfid_sales + curr.gross_credit_sales+curr.total_digital_surcharge - curr.total_discounts) / 100;

      if (groupedRows[username]) {
        const row = groupedRows[username];
          row.total_gross_sales += total_gross_sales;
          row.gross_cash_balance_sales += gross_cash_balance_sales;
          row.gross_cash_sales += gross_cash_sales;
          row.gross_credit_sales += gross_credit_sales;
          row.gross_promo_balance_sales += gross_promo_balance_sales;
          row.net_promo_balance_sales += net_promo_balance_sales;
          row.gross_rfid_sales += gross_rfid_sales;
          row.total_tax += total_tax;
          row.total_tips += total_tips;
          row.total_digital_surcharge += total_digital_surcharge;
          row.total_discounts += total_discounts;
          row.total_net_sales += total_net_sales;
          row.merchant_processed += merchant_processed;
          row.net_refunds += net_refunds;
          row.total_refunds += total_refunds;
          row.gross_cash_balance_tips = gross_cash_balance_tips;
          row.gross_promo_balance_tips += gross_promo_balance_tips;
          row.gross_credit_tips += gross_credit_tips;
          row.gross_rfid_tips += gross_rfid_tips;
          row.gross_token_sales += gross_token_sales;
          row.net_token_sales += net_token_sales;
          row.cash_transaction_count += cash_transaction_count;
          row.credit_transaction_count += credit_transaction_count;
          row.rfid_transaction_count += rfid_transaction_count;
          row.cash_balance_transaction_count += cash_balance_transaction_count;
          row.promo_balance_transaction_count += promo_balance_transaction_count;
          row.token_transaction_count += token_transaction_count;
      } else {
        groupedRows[username] = {
          ...curr,
          id: username,
          total_gross_sales,
          gross_cash_balance_sales,
          gross_cash_sales,
          gross_credit_sales,
          gross_promo_balance_sales,
          net_promo_balance_sales,
          gross_rfid_sales,
          net_refunds,
          total_refunds,
          total_tax,
          total_tips,
          merchant_processed,
          total_digital_surcharge,
          total_discounts,
          total_net_sales,
          gross_cash_balance_tips,
          gross_promo_balance_tips,
          gross_credit_tips,
          gross_rfid_tips,
          gross_token_sales,
          net_token_sales,
          cash_transaction_count,
          credit_transaction_count,
          rfid_transaction_count,
          cash_balance_transaction_count,
          promo_balance_transaction_count,
          token_transaction_count,
        };
      }
    totalRow.total_gross_sales += total_gross_sales;
    totalRow.gross_cash_balance_sales += gross_cash_balance_sales;
    totalRow.gross_cash_sales += gross_cash_sales;
    totalRow.gross_credit_sales += gross_credit_sales;
    totalRow.gross_promo_balance_sales += gross_promo_balance_sales;
    totalRow.gross_rfid_sales += gross_rfid_sales;
    totalRow.total_tax += total_tax;
    totalRow.total_tips += total_tips;
    totalRow.total_digital_surcharge += total_digital_surcharge;
    totalRow.total_discounts += total_discounts;
    totalRow.total_net_sales += total_net_sales;
    totalRow.gross_cash_balance_tips += gross_cash_balance_tips;
    totalRow.gross_promo_balance_tips += gross_promo_balance_tips;
    totalRow.gross_credit_tips += gross_credit_tips;
    totalRow.gross_rfid_tips += gross_rfid_tips;
    totalRow.gross_token_sales += gross_token_sales;
    totalRow.merchant_processed += merchant_processed;
    totalRow.net_token_sales += net_token_sales;
    totalRow.net_promo_balance_sales += net_promo_balance_sales;
    totalRow.net_refunds += net_refunds;
    totalRow.total_refunds += total_refunds;
    totalRow.cash_transaction_count += cash_transaction_count;
    totalRow.credit_transaction_count += credit_transaction_count;
    totalRow.rfid_transaction_count += rfid_transaction_count;
    totalRow.cash_balance_transaction_count += cash_balance_transaction_count;
    totalRow.promo_balance_transaction_count += promo_balance_transaction_count;
    totalRow.token_transaction_count += token_transaction_count;
  });

  groupedRows[totalRow.id] = totalRow;

  return Object.values(groupedRows);
};