import { gql } from "@apollo/client";

export const REPORTS_VENDOR = gql`
  query reports_vendor_query($where: reports_financial_hourly_bool_exp) {
    reports_financial_hourly(where: $where, order_by: { vendor_name: asc }) {
      vendor_id
      vendor_type
      vendor_name
    transaction_hour
    total_transaction_count
    total_tips
    total_tax
    total_refunds
    net_refunds
    total_gross_sales
    total_discounts
    total_digital_surcharge
    token_transaction_count
    rfid_transaction_count
    promo_balance_transaction_count
    organization_name
    organization_id
    net_promo_balance_sales
    menu_name
    menu_id
    location_name
    location_id
    gross_token_sales
    gross_rfid_tips
    gross_rfid_sales
    gross_promo_balance_tips
    gross_promo_balance_sales
    gross_credit_tips
    gross_credit_sales
    gross_cash_sales
    gross_cash_balance_tips
    gross_cash_balance_sales
    event_name
    event_id
    credit_transaction_count
    cash_transaction_count
    cash_balance_transaction_count
    }
  }
`;
