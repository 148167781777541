import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { classNames } from "../../utils";

interface InputProps {
  label?: string;
  error?: string;
  type?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (value: string | number | undefined) => void;
  onEnter?: (value: string | number | undefined) => void;
  defaultValue?: string | number;
  maxLength?: number;
  max?: number;
  updateValue?: boolean;
  value?: string;
}

export function Input({
  label,
  error,
  name,
  type = "text",
  placeholder,
  required,
  defaultValue,
  onChange,
  onEnter,
  updateValue = false,
  ...rest
}: InputProps) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };
  useEffect(() => {
    if (updateValue) {
      setValue(defaultValue);
    }
  }, [updateValue, defaultValue]);
  return (
    <>
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            value={value}
            id={name}
            name={name}
            type={type}
            onChange={handleChange}
            className={classNames(
              "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
              error
                ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                : ""
            )}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onEnter && onEnter(value);
              }
            }}
            placeholder={placeholder}
            defaultValue={defaultValue}
            required={required}
            {...rest}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600" id="error">
            {error}
          </p>
        )}
      </div>
    </>
  );
}

export function TextArea({
  label,
  error,
  name,
  placeholder,
  defaultValue,
  ...rest
}: InputProps) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <textarea
          id={name}
          name={name}
          className={classNames(
            "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
            error
              ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : ""
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          // {...methods.register(name)}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
}

export function PasswordInput({
  label,
  error,
  name,
  placeholder,
  required,
  defaultValue,
  onChange,
  onEnter,
  ...rest
}: InputProps) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };

  const toggleVisible = () => setVisible(!visible);

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={name}
          name={name}
          type={visible ? "text" : "password"}
          onChange={handleChange}
          className={classNames(
            "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
            error
              ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : ""
          )}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter && onEnter(value);
            }
          }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          required={required}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {visible ? (
          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
            <EyeSlashIcon
              onClick={toggleVisible}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
            <EyeIcon
              onClick={toggleVisible}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
}
