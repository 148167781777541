import React from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router-dom';
import QRCode from 'react-qr-code';
import { QRCodeSVG } from 'qrcode.react';

import { GET_PROMO_CODE, GET_PROMO_CODE_PK } from '../promo-code/queries';
import { createApolloClient } from '../../providers/ApolloClientFactory';
import { PromoCode } from '../../types/promoCode';

const client = createApolloClient();

export const QrCodeDisplayLoader = async ({ params }: LoaderFunctionArgs) => {
  const { uid } = params;
  console.log({ uid });
  const { data } = await client.query({
    query: GET_PROMO_CODE,
    variables: {
      where: {
        uid: {
          _eq: uid,
        },
      },
    },
  });

  return {
    data: data.promo_code?.[0],
  };
};

export const QrCodeDisplay = () => {
  const { data } = useLoaderData() as {
    data: PromoCode;
  };
  console.log({ data });
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        padding: '16px',
        height: '100vh',
      }}
    >
      <div>
        <div>
          <h1 className="text-center text-2xl mb-2">
            <strong>{data.event.name}</strong> for <strong>{data?.description}</strong> - Promo Code!{' '}
          </h1>
          {/* <p>{data.event.description} - Promo Code! </p> */}
          <p className="text-center">Display the below QR Code at the Point-Of-Sale Terminal</p>
          <div>
            <QRCodeSVG value={data.uid} width={'30vw'} height={'40vw'} />
          </div>
        </div>

        {/* <QRCode
        value={}
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
      /> */}
      </div>
    </div>
  );
};
