import { ColumnDef } from "@tanstack/table-core";
import moment from "moment";
import { Link, useLoaderData } from "react-router-dom";
import Table from "../../../components/Table";
import { Order } from "../../../types/order";
import { Transaction } from "../../../types/transaction";
import { capitalize, usdFormat } from "../../../utils";

const transactionColumns: ColumnDef<Transaction>[] = [
  {
    header: "Reference ID",
    accessorKey: "payment.reference_id",
    cell: (info) => (
      <Link to={`/transactions/${info.row.original.id}`}>
        {info.getValue<string>()}
      </Link>
    ),
  },
  {
    header: "Transaction Created",
    accessorKey: "created_at",
    cell: (info) => moment(info.getValue<string>()).format("lll"),
  },
  {
    header: "Transaction Updated",
    accessorKey: "updated_at",
    cell: (info) => moment(info.getValue<string>()).format("lll"),
  },
  {
    header: "Transaction Status",
    accessorKey: "status",
    cell: (info) =>
      info.getValue<string>() === "refund"
        ? "Refunded"
        : capitalize(info.getValue<string>()),
  },
  {
    header: "Amount",
    accessorKey: "amount",
    cell: (info) => usdFormat.format(info.getValue<number>() / 100),
  },
];

export const TransactionTable = () => {
  const order = useLoaderData() as Order;
  return <Table columns={transactionColumns} data={order.transactions} />;
};
